.hide {
  opacity: 0;
  z-index: -1;
}

.show {
  display: block;
}

.rotate {
  animation: rotation 0.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-slowstop {
  animation: slowstop 1.5s linear;
}

@keyframes slowstop {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(90deg);
  }
  20% {
    -webkit-transform: rotate(180deg);
  }
  40% {
    -webkit-transform: rotate(270deg);
  }
  70% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(450deg);
  }
}
