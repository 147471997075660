.mdlink a {
  text-decoration: none;
}

.mdlink a:link,
.mdlink a:visited,
.mdlink a:hover {
  color: #d30;
}

.mdlink a:hover {
  text-decoration: underline;
}

.inline-code {
  margin: 0.2em;
  font-weight: bold;
}

.blockquote {
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #d30 solid;
}

.invisible-icon svg {
  transform: translateY(4px);
  margin-left: 5px;
}

.double-tick-button svg:last-child {
  transform: translateX(-10px);
}