.horizontal-scroll pre {
  overflow: auto;
  padding: 12px;
  max-height: 67vh;
}

.horizontal-scroll.light pre {
  background-color: hsl(0,0%,97%);
}

.horizontal-scroll.dark pre {
  background-color: hsl(0,0%,7%);
}
